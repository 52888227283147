import {Component, OnInit} from '@angular/core';
import {FormatService} from '../../../../services/format.service';
import {Destroyed} from '../../directives/destroyed.directive';
import {ParamWebservice} from '../../../../services/webservices/param.webservice';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {ReplaySubject} from 'rxjs';
import {EntrepriseWebservice} from '../../../../services/webservices/entreprise.webservice';
import {PInterestField} from '../../../../model/param/p-interest-field.model';
import {WorkDurationEnum} from '../../../../model/enums/work-duration.enum';
import {AlertService} from '../../../../services/alert.service';
import {YoungWebservice} from '../../../../services/webservices/young.webservice';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {Monitoring} from '../../../../model/monitoring/monitoring.model';
import {MonitoringMetaDTO} from '../../../../model/dto/monitoring-meta.dto';
import {PStatutChangeMotif} from '../../../../model/param/p-statut-change-motif.model';
import {PStatutSituationComplement} from '../../../../model/param/p-statut-situation-complement.model';
import {MonitoringInterestField} from '../../../../model/monitoring/monitoring-interest-field.model';
import {WorkExperienceDTO} from '../../../../model/dto/work-experience.dto';
import {DateAdapter, MatOptionModule} from '@angular/material/core';
import {CustomDateAdapter} from '../../../../utils/custom-date-adapter';
import {getEnumKeysAsNumber, isNotNullOrUndefined} from '../../../../utils/utils.static';
import {PMonitoringStatus} from '../../../../model/param/p-monitoring-status.model';
import {PSituationAtDate} from '../../../../model/param/p-situation-at-date.model';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {SwitchComponent} from '../../form-parts/form-items/switch/switch.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {AlertComponent} from '../../alert/alert/alert.component';
import {NgClass, NgFor, NgIf} from '@angular/common';

@Component({
  selector: 'app-dialog-add-monitoring',
  templateUrl: './dialog-add-monitoring.component.html',
  styleUrls: ['./dialog-add-monitoring.component.scss'],
  providers: [{provide: DateAdapter, useClass: CustomDateAdapter}],
  standalone: true,
  imports: [
    MatDialogTitle,
    NgIf,
    AlertComponent,
    FormsModule,
    ReactiveFormsModule,
    MatDialogContent,
    MatSelectModule,
    NgClass,
    NgFor,
    MatOptionModule,
    MatDatepickerModule,
    SwitchComponent,
    MatDialogActions,
    MatButtonModule,
    MatProgressSpinnerModule
  ]
})
export class DialogAddMonitoringComponent extends Destroyed implements OnInit {
  monitoringFormGroup: UntypedFormGroup;

  monitoring: Monitoring;
  idUser: number;

  allStatusChangeMotif: PStatutChangeMotif[] = [];
  allStatusSituationComplement: PStatutSituationComplement[] = [];
  allSituationAtDate: PSituationAtDate[] = [];

  statusChangeMotif = [];
  statusSituationComplement = [];

  workDurationForSelect = [];
  allPMonitoringStatuses: PMonitoringStatus[] = [];
  currentPMonitoringStatuses: PMonitoringStatus[] = [];

  workExperiences: WorkExperienceDTO[] = [];

  //Interest field
  public interestFieldMultiFilterCtrl = new UntypedFormControl();
  public filteredInterestObservable: ReplaySubject<PInterestField[]> = new ReplaySubject<
    PInterestField[]
  >(1);
  public selectedInterest: PInterestField[];

  isPausedOrOut = false;
  isOutOrPost = false;
  isPostOut = false;

  isStudy = false;
  isWork = false;

  oldStatus: number;
  isReadOnly: boolean;

  isSaving = false;

  isLoading = true;

  constructor(
    private readonly dialogRef: MatDialogRef<DialogAddMonitoringComponent>,
    private readonly fb: UntypedFormBuilder,
    private readonly formatService: FormatService,
    private readonly paramWebservice: ParamWebservice,
    private readonly entrepriseWebservice: EntrepriseWebservice,
    private readonly alertService: AlertService,
    private readonly youngWebservice: YoungWebservice
  ) {
    super();
    this.monitoringFormGroup = this.fb.group({
      status: [null, Validators.required],
      dateMonitoringChange: new Date(),
      statusChangeMotif: null,
      situationAtDate: null,
      situationComplement: null,
      workExperience: null,
      comment: null,
      isOutQuestionnaireDone: false,
      establishedContact: false,
      interestFields: null,
      workDuration: null
    });

    this.initOnValueChangesFormGroup();
    this.interestFieldMultiFilterCtrl.setValue('');
    this.initAllEnum();
    this.onSearchInterest();
  }

  get fMonitoring() {
    return this.monitoringFormGroup.controls;
  }

  ngOnInit() {
    this.currentPMonitoringStatuses = this.allPMonitoringStatuses;
    if (this.monitoring && this.monitoring.id > 0) {
      this.youngWebservice
        .getMonitoring(this.monitoring.id)
        .pipe(this.untilDestroyed())
        .subscribe((value: Monitoring) => {
          this.monitoring = value;
          this.setForm();
          this.isReadOnly = true;
          this.toggleDisable(true);
          this.initYoungWorkExperience();
        });
    } else {
      this.monitoring = new Monitoring();
      this.checkLastStatus();
      this.initYoungWorkExperience();
    }
    this.isLoading = false;
  }

  checkLastStatus() {
    this.youngWebservice
      .getLastStatus(this.idUser)
      .pipe(this.untilDestroyed())
      .subscribe((value: number) => {
        this.oldStatus = value;
        this.currentPMonitoringStatuses = this.currentPMonitoringStatuses.filter(
          (item) => item.id !== value
        );
      });
  }

  initOnValueChangesFormGroup() {
    this.fMonitoring.status.valueChanges.pipe(this.untilDestroyed()).subscribe((value) => {
      if (isNotNullOrUndefined(value)) {
        if (value === 1) {
          this.isPausedOrOut = true;
          this.isOutOrPost = false;
          this.isPostOut = false;

          this.statusChangeMotif = this.allStatusChangeMotif.filter(
            (item) => item.idPMonitoringStatus === value
          );
        }

        if (value === 2) {
          this.isPausedOrOut = false;
          this.isOutOrPost = false;
          this.isPostOut = false;

          this.statusChangeMotif = [];
        }

        if (value === 3) {
          this.isPausedOrOut = true;
          this.isOutOrPost = true;
          this.isPostOut = true;


          this.statusChangeMotif = this.allStatusChangeMotif.filter(
            (item) => item.idPMonitoringStatus === value
          );
        }

        // si sorti depuis 3/6 mois ou plus alors on desactive workExperience
        if (value === 4 || value === 5 || value === 6) {
          this.fMonitoring.workExperience.clearValidators();
          this.fMonitoring.workExperience.updateValueAndValidity();
        } else {
          this.setExperienceAfterValidator()
        }

        if (value > 3) {
          this.isPausedOrOut = false;
          this.isOutOrPost = true;
          this.isPostOut = true;

          this.statusChangeMotif = this.allStatusChangeMotif.filter(
            (item) => item.idPMonitoringStatus === 2
          );
        }

        this.setValidators(value);
      }
    });

    this.fMonitoring.situationAtDate.valueChanges.pipe(this.untilDestroyed()).subscribe((value) => {
      if (isNotNullOrUndefined(value)) {
        if (value === 1) {
          this.isStudy = true;
          this.isWork = false;
          this.setComplementValidator();
          this.clearExperienceAfterValidator();
        } else if (value === 2) {
          this.isStudy = false;
          this.isWork = true;
          this.setComplementValidator();

          if (this.workExperiences.length > 0 && this.monitoringFormGroup.controls.status.value !== 6) {
            this.setExperienceAfterValidator();
          }
        } else {
          this.isStudy = false;
          this.isWork = false;
          this.clearExperienceAfterValidator();
        }

        this.statusSituationComplement = this.allStatusSituationComplement.filter(
          (item) => item.idPSituationAtDate === value
        );

        if (value === 5) {
          this.fMonitoring.establishedContact.setValue(false);
        } else {
          this.fMonitoring.establishedContact.setValue(true);
        }

        if (value > 2) {
          this.clearComplementValidator();
        }
      }
    });
  }

  setExperienceAfterValidator() {
    this.fMonitoring.workExperience.setValidators(Validators.required);
    this.fMonitoring.workExperience.updateValueAndValidity();
  }

  clearExperienceAfterValidator() {
    this.fMonitoring.workExperience.clearValidators();
    this.fMonitoring.workExperience.updateValueAndValidity();
  }

  setComplementValidator() {
    this.fMonitoring.situationComplement.setValidators(Validators.required);
  }

  clearComplementValidator() {
    this.fMonitoring.situationComplement.clearValidators();
    this.fMonitoring.situationComplement.updateValueAndValidity();
  }

  setValidators(value) {
    if (value === 1) {
      this.fMonitoring.statusChangeMotif.setValidators(Validators.required);
      this.fMonitoring.situationAtDate.clearValidators();
      this.fMonitoring.establishedContact.clearValidators();
    } else if (value === 2) {
      this.fMonitoring.statusChangeMotif.clearValidators();
      this.fMonitoring.situationAtDate.clearValidators();
      this.fMonitoring.establishedContact.clearValidators();
    } else if (value === 3) {
      this.fMonitoring.statusChangeMotif.setValidators(Validators.required);
      this.fMonitoring.situationAtDate.setValidators(Validators.required);
      this.fMonitoring.establishedContact.clearValidators();
    } else if (value > 3) {
      this.fMonitoring.statusChangeMotif.clearValidators();
      this.fMonitoring.situationAtDate.setValidators(Validators.required);
      this.fMonitoring.establishedContact.setValidators(Validators.required);
    }
    this.updateAllValueAndValidity();
  }

  updateAllValueAndValidity() {
    this.fMonitoring.statusChangeMotif.updateValueAndValidity();
    this.fMonitoring.situationAtDate.updateValueAndValidity();
    this.fMonitoring.establishedContact.updateValueAndValidity();
  }

  initAllEnum() {
    this.paramWebservice
      .getAllEnumForMonitoring()
      .pipe(this.untilDestroyed())
      .subscribe((content: MonitoringMetaDTO) => {
        this.allStatusChangeMotif = content.pStatusChangeMotif;
        this.allStatusSituationComplement = content.pStatutSituationComplement;
        this.allSituationAtDate = content.pSituationAtDates;
        this.selectedInterest = content.pInterestFields;
        this.filteredInterestObservable.next(this.selectedInterest.slice());
      });

    this.initWorkTime();
  }

  initWorkTime() {
    this.workDurationForSelect = [];

    getEnumKeysAsNumber(WorkDurationEnum).forEach((i) =>
      this.workDurationForSelect.push({id: i, name: this.formatService.formatWorkDuration(i)})
    );
  }

  initYoungWorkExperience() {
    this.youngWebservice
      .getAllWorkExperience(this.idUser)
      .pipe(this.untilDestroyed())
      .subscribe((content: WorkExperienceDTO[]) => {
        this.workExperiences = content;
      });
  }

  formatWorkExperienceName(workExperience: WorkExperienceDTO) {
    let workExperienceName =
      workExperience.category + ' - ' + workExperience.type + ' - ' + workExperience.structureName;
    if (workExperience.dateBegin || workExperience.dateEnd) {
      workExperienceName +=
        ' (' +
        (workExperience.dateBegin
          ? this.formatService.formatDate(new Date(workExperience.dateBegin))
          : 'Aucune date de début renseignée') +
        ' - ' +
        (workExperience.dateEnd
          ? this.formatService.formatDate(new Date(workExperience.dateEnd))
          : 'Aucune date de fin renseignée') +
        ')';
    }

    return workExperienceName;
  }

  checkBeforeSubmit() {
    const errors = [];

    if (this.fMonitoring.status.invalid) {
      errors.push('Status');
    }

    if (this.fMonitoring.dateMonitoringChange.invalid) {
      errors.push('Date du changement de statut');
    }

    if (this.isPausedOrOut) {
      if (this.fMonitoring.statusChangeMotif.invalid) {
        errors.push('Motif de changement');
      }
    }

    errors.push(...this.checkWorkSutdyCondition());

    if (this.isOutOrPost) {
      if (this.fMonitoring.situationAtDate.invalid) {
        errors.push('Situation à date');
      }
    }

    if (this.isPostOut) {
      if (this.fMonitoring.establishedContact.invalid) {
        errors.push('Contact établi');
      }
    }

    if (errors.length > 0) {
      const message = 'Les champs suivants sont manquants ou erronés : ' + errors.join(', ');

      this.alertService.clear('monitoringAlert');
      this.alertService.error(message, 'monitoringAlert');
    }
  }

  checkWorkSutdyCondition() {
    const errors = [];
    if((this.isWork && !this.isStudy) || (!this.isWork && this.isStudy)) {
      if (this.fMonitoring.situationComplement.invalid) {
        errors.push(('Motif Complément de situation'));
      }
      if(this.isWork && !this.isStudy && this.fMonitoring.workExperience.invalid) {
        errors.push(('Motif Expérience à la sortie'));
      }
    }
    return errors;
  }

  saveMonitoring() {
    this.isSaving = true;
    this.checkBeforeSubmit();
    if (this.monitoringFormGroup.valid) {
      this.prepareMonitoring();

      this.youngWebservice
        .addOrUpdateMonitoring(this.monitoring)
        .pipe(this.untilDestroyed())
        .subscribe(() => {
          this.isSaving = false;
          if (this.monitoring.id > 0) {
            this.toggleDisable(true);
          } else {
            this.dialogRef.close(true);
          }
        });
    } else {
      this.isSaving = false;
    }
  }

  prepareMonitoring() {
    this.monitoring.idYoung = this.idUser;
    if (isNotNullOrUndefined(this.fMonitoring.status.value)) {
      this.monitoring.idPMonitoringStatus = this.fMonitoring.status.value;
    }

    this.monitoring.dateMonitoringChange = this.fMonitoring.dateMonitoringChange.value;

    if (this.fMonitoring.statusChangeMotif.value) {
      this.monitoring.idPStatutChangeMotif = this.fMonitoring.statusChangeMotif.value;
    }

    if (isNotNullOrUndefined(this.fMonitoring.situationAtDate.value)) {
      this.monitoring.idPSituationAtDate = this.fMonitoring.situationAtDate.value;
    }

    if (this.fMonitoring.situationComplement.value && this.statusSituationComplement.length > 0) {
      this.monitoring.idPStatutSituationComplement = this.fMonitoring.situationComplement.value;
    } else {
      this.monitoring.idPStatutSituationComplement = null;
    }

    if (this.fMonitoring.workExperience.value) {
      this.monitoring.idWorkExperience = this.fMonitoring.workExperience.value;
    }

    this.checkIfMonitoringNotNull();

    if (this.fMonitoring.interestFields.value && this.fMonitoring.interestFields.value.length > 0) {
      this.fMonitoring.interestFields.value.forEach((item) => {
        const monitoringInterestField = new MonitoringInterestField();
        monitoringInterestField.idPInterestField = item;
        this.monitoring.listOfMonitoringInterestField.push(monitoringInterestField);
      });
    }
  }

  checkIfMonitoringNotNull() {
    if (isNotNullOrUndefined(this.fMonitoring.comment.value)) {
      this.monitoring.comment = this.fMonitoring.comment.value;
    }

    if (isNotNullOrUndefined(this.fMonitoring.isOutQuestionnaireDone.value)) {
      this.monitoring.isOutQuestionnaireDone = this.fMonitoring.isOutQuestionnaireDone.value;
    } else {
      this.monitoring.isOutQuestionnaireDone = false;
    }

    if (isNotNullOrUndefined(this.fMonitoring.establishedContact)) {
      this.monitoring.establishedContact = this.fMonitoring.establishedContact.value;
    } else {
      this.monitoring.establishedContact = false;
    }

    if (isNotNullOrUndefined(this.fMonitoring.workDuration.value)) {
      this.monitoring.workDuration = this.fMonitoring.workDuration.value;
    }
  }

  setForm() {
    this.idUser = this.monitoring.idYoung;

    if (isNotNullOrUndefined(this.monitoring.idPMonitoringStatus)) {
      this.fMonitoring.status.setValue(this.monitoring.idPMonitoringStatus);
    }

    this.fMonitoring.dateMonitoringChange.setValue(this.monitoring.dateMonitoringChange);

    if (this.monitoring.idPStatutChangeMotif) {
      this.fMonitoring.statusChangeMotif.setValue(this.monitoring.idPStatutChangeMotif);
    }

    if (isNotNullOrUndefined(this.monitoring.idPSituationAtDate)) {
      this.fMonitoring.situationAtDate.setValue(this.monitoring.idPSituationAtDate);
    }

    if (this.monitoring.idPStatutSituationComplement) {
      this.fMonitoring.situationComplement.setValue(this.monitoring.idPStatutSituationComplement);
    }

    if (this.monitoring.idWorkExperience) {
      this.fMonitoring.workExperience.setValue(this.monitoring.idWorkExperience);
    }

    if (isNotNullOrUndefined(this.monitoring.comment)) {
      this.fMonitoring.comment.setValue(this.monitoring.comment);
    }

    if (isNotNullOrUndefined(this.monitoring.isOutQuestionnaireDone)) {
      this.fMonitoring.isOutQuestionnaireDone.setValue(this.monitoring.isOutQuestionnaireDone);
    }

    if (isNotNullOrUndefined(this.monitoring.establishedContact)) {
      this.fMonitoring.establishedContact.setValue(this.monitoring.establishedContact);
    }

    if (isNotNullOrUndefined(this.monitoring.workDuration)) {
      this.fMonitoring.workDuration.setValue(this.monitoring.workDuration);
    }

    this.handleListOfMonitoring();
  }

  handleListOfMonitoring() {
    if (
      this.monitoring.listOfMonitoringInterestField &&
      this.monitoring.listOfMonitoringInterestField.length > 0
    ) {
      this.fMonitoring.interestFields.setValue(
        this.monitoring.listOfMonitoringInterestField.map((item) => item.idPInterestField)
      );
    }
  }

  toggleDisable(value: boolean) {
    this.isReadOnly = value;
    if (this.isReadOnly) {
      if (this.monitoring.id > 0) {
        this.fMonitoring.status.disable();
        this.fMonitoring.statusChangeMotif.disable();
        this.fMonitoring.dateMonitoringChange.disable();
        this.fMonitoring.situationAtDate.disable();
        this.fMonitoring.situationComplement.disable();
        this.fMonitoring.workExperience.disable();
        this.fMonitoring.comment.disable();
        this.fMonitoring.isOutQuestionnaireDone.disable();
        this.fMonitoring.establishedContact.disable();
        this.fMonitoring.workDuration.disable();
        this.fMonitoring.interestFields.disable();
      } else {
        this.dismiss();
      }
    } else {
      this.fMonitoring.dateMonitoringChange.enable();
      this.fMonitoring.statusChangeMotif.enable();
      this.fMonitoring.situationAtDate.enable();
      this.fMonitoring.situationComplement.enable();
      this.fMonitoring.workExperience.enable();
      this.fMonitoring.comment.enable();
      this.fMonitoring.isOutQuestionnaireDone.enable();
      this.fMonitoring.establishedContact.enable();
      this.fMonitoring.workDuration.enable();
      this.fMonitoring.interestFields.enable();
    }
  }

  compareWith(e1, e2) {
    return e1 === e2;
  }

  compareWithId(e1, e2) {
    return e1.id === e2.id;
  }

  dismiss() {
    this.dialogRef.close();
  }

  private onSearchInterest() {
    this.interestFieldMultiFilterCtrl.valueChanges.pipe(this.untilDestroyed()).subscribe(() => {
      this._filterInterest();
    });
  }

  private _filterInterest() {
    if (!this.selectedInterest) {
      return;
    }
    // get the search keyword
    let search = this.interestFieldMultiFilterCtrl.value;
    if (!search) {
      this.filteredInterestObservable.next(this.selectedInterest.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredInterestObservable.next(
      this.selectedInterest.filter((interest) => interest.label.toLowerCase().indexOf(search) > -1)
    );
  }
}
