<div class="d-flex flex-column">
  <div class="d-flex flex-row m-2">
    <h3 mat-dialog-title>{{this.monitoring && this.monitoring.id > 0 ? 'Modifier ' : 'Ajouter ' }} un suivi </h3>
    <span class="flex-fill separator"></span>
    <button (click)="dismiss()"
            aria-label="Close"
            class="close"
            type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <ng-container *ngIf="!isLoading; else loadingMonitoring">
    <app-alert id="monitoringAlert"></app-alert>
    <form [formGroup]="monitoringFormGroup">
      <mat-dialog-content>
        <div class="d-flex flex-row align-items-center my-2 mx-2">
          <div class="col-md-6">
            <label class="star">Statut du jeune</label>
            <mat-select
              [ngClass]="{'is-invalid': (fMonitoring.status.dirty || fMonitoring.status.touched) && fMonitoring.status.invalid, 'disabled': fMonitoring.status.disabled}"
              class="custom-select"
              formControlName="status">
              <mat-option *ngFor="let item of currentPMonitoringStatuses" [value]="item.id">{{item.label}}</mat-option>
            </mat-select>
          </div>
          <div class="col-md-6">
            <label class="label-info spanForm">Date du changement de statut</label>
            <div class="d-flex flex-row">
              <input [matDatepicker]="dateMonitoringChange" autocomplete="off" class="form-control"
                     disabled
                     formControlName="dateMonitoringChange">
              <mat-datepicker #dateMonitoringChange></mat-datepicker>
            </div>
          </div>
        </div>

                    <ng-container>
                        <div *ngIf="isPausedOrOut" class="d-flex flex-row align-items-center my-2 mx-2">
                            <div class="col-md-6">
                                <label class="star">Motif de changement de statut</label>
                                <mat-select [compareWith]="compareWith"
                                            [ngClass]="{'is-invalid': (fMonitoring.statusChangeMotif.dirty || fMonitoring.statusChangeMotif.touched) && fMonitoring.statusChangeMotif.invalid, 'disabled': fMonitoring.statusChangeMotif.disabled}"
                                            class="custom-select"
                                            formControlName="statusChangeMotif">
                                    <mat-option *ngFor="let item of statusChangeMotif"
                                                [value]="item.id">{{item.label}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="d-flex flex-row align-items-center my-2 mx-2">
                            <ng-container *ngIf="isOutOrPost">
                                <div class="col-md-6">
                                    <label class="star">Situation à date</label>
                                    <mat-select [compareWith]="compareWith"
                                                [ngClass]="{'is-invalid': (fMonitoring.situationAtDate.dirty || fMonitoring.situationAtDate.touched) && fMonitoring.situationAtDate.invalid, 'disabled': fMonitoring.situationAtDate.disabled}"
                                                class="custom-select"
                                                formControlName="situationAtDate">
                                        <mat-option *ngFor="let item of allSituationAtDate"
                                                    [value]="item.id">{{item.label}}</mat-option>
                                    </mat-select>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="isPostOut && statusSituationComplement?.length > 0">
                                <div class="col-md-6">
                                    <label class="star">Complément de situation</label>
                                    <mat-select [compareWith]="compareWith"
                                                [ngClass]="{'is-invalid': (fMonitoring.situationComplement.dirty || fMonitoring.situationComplement.touched) && fMonitoring.situationComplement.invalid, 'disabled': fMonitoring.situationComplement.disabled}"
                                                class="custom-select"
                                                formControlName="situationComplement">
                                        <mat-option *ngFor="let item of statusSituationComplement"
                                                    [value]="item.id">{{item.label}}</mat-option>
                                    </mat-select>
                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf="fMonitoring.status.value === 3"
                             class="d-flex flex-row align-items-center my-2 mx-2">
                            <div class="col-md-12">
                                <label [ngClass]="workExperiences.length > 0 && isWork && !isStudy ? 'star' : ''">Expérience à la sortie</label>
                                <mat-select *ngIf="workExperiences.length > 0; else noWorkExperience"
                                            [compareWith]="compareWith"
                                            [ngClass]="{'is-invalid': (fMonitoring.workExperience.dirty || fMonitoring.workExperience.touched) && fMonitoring.workExperience.invalid, 'disabled': fMonitoring.workExperience.disabled}"
                                            class="custom-select"
                                            formControlName="workExperience">
                                    <mat-option *ngFor="let item of workExperiences"
                                                [value]="item.id">{{formatWorkExperienceName(item)}}</mat-option>
                                </mat-select>

                                <ng-template #noWorkExperience>
                                    <input class="form-control" disabled value="Aucune expérience saisie"/>
                                </ng-template>
                            </div>
                        </div>

                        <div class="d-flex flex-row align-items-center my-2 mx-2">
                            <ng-container *ngIf="fMonitoring.status.value === 3">
                                <div class="col-md-6 pt-2 d-flex flex-row">
                                    <label>Questionnaire de sortie réalisé</label>
                                    <app-switch [(modelAttribute)]="monitoring.isOutQuestionnaireDone"
                                                [disabled]="isReadOnly" [group]="monitoringFormGroup" class="col-md-6"
                                                fcName="isOutQuestionnaireDone"
                                                textLabel="Non"
                                                textLabelChecked="Oui"></app-switch>
                                </div>
                            </ng-container>
                        </div>

                        <div class="d-flex flex-row align-items-center my-2 mx-2">
                            <ng-container *ngIf="isPostOut && isWork">
                                <div class="col-md-6">
                                    <label>Temps de travail</label>
                                    <mat-select
                                            [ngClass]="{'is-invalid': (fMonitoring.workDuration.dirty || fMonitoring.workDuration.touched) && fMonitoring.workDuration.invalid, 'disabled': fMonitoring.workDuration.disabled}"
                                            class="custom-select"
                                            formControlName="workDuration">
                                        <mat-option *ngFor="let item of workDurationForSelect"
                                                    [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                </div>
                            </ng-container>
                        </div>
                        <div class="d-flex flex-column align-items-center my-2 mx-2">
                            <div class="col-md-12">
                                <label>Commentaire</label>
                                <textarea [ngClass]="{'disabled': fMonitoring.comment.disabled}"
                                          class="col-lg-12 form-control"
                                          formControlName="comment"></textarea>
                            </div>
                        </div>
                    </ng-container>
                </mat-dialog-content>
                <mat-dialog-actions align="end">
                    <ng-container *ngIf="!isReadOnly else readOnly">
                        <button (click)="saveMonitoring()" *ngIf="!isSaving else btnLoading" class="btn btn-primary m-2"
                                mat-button
                                type="submit">
                            Enregistrer
                        </button>
                        <button (click)="toggleDisable(true)" class="m-2" mat-button>
                            Annuler
                        </button>
                    </ng-container>

                    <ng-template #readOnly>
                        <button (click)="toggleDisable(false)" *ngIf="!isSaving else btnLoading"
                                class="btn btn-primary m-2"
                                mat-button>
                            Modifier
                        </button>
                        <button (click)="dismiss()" class="m-2" mat-button>
                            Fermer
                        </button>
                    </ng-template>
                </mat-dialog-actions>
            </form>
    </ng-container>
    <ng-template #loadingMonitoring>
        <mat-spinner [diameter]="100" class="custom-theme" color="primary"></mat-spinner>
    </ng-template>
</div>


<ng-template #btnLoading>
    <button class="mat-button m-4" mat-button>
        <mat-spinner [diameter]="50" class="custom-theme" color="primary"></mat-spinner>
    </button>
</ng-template>
