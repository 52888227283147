import {HttpBackend, HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {VisitCreationDTO} from 'src/app/model/dto/visit-creation-infos.dto';
import {VisitForUpdateDTO} from 'src/app/model/dto/visit-dto/visit-update.dto';
import {PageRequest} from 'src/app/model/request/pageRequest';
import {environment} from 'src/environments/environment';
import {getMobileToken} from 'src/app/utils/utils.static';

@Injectable({
  providedIn: 'root'
})
export class FamilyVisitWebService {
  private readonly root = `${environment.envApiUrl}/visitFamily`;

  private readonly httpMobile: HttpClient;

  constructor(private readonly http: HttpClient,
              private readonly httpBackend: HttpBackend) {
    this.httpMobile = new HttpClient(httpBackend);
  }

  getYoungFamilyVisits(listOfIdYoung: number[], pageRequest: PageRequest, onlySigned: boolean) {
    const body = {
      pageNumber: pageRequest.pageNumber,
      pageSize: pageRequest.pageSize,
      listOfIdYoung: listOfIdYoung,
      onlySigned: onlySigned
    };
    return this.http.post(`${this.root}/getYoungsFamilyVisits`, body);
  }

  getFamilyVisit(visitId: number) {
    const token = getMobileToken();
    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      return this.httpMobile.get(`${this.root}/getFamilyVisit/${visitId}`, options);
    } else {
      return this.http.get(`${this.root}/getFamilyVisit/${visitId}`);
    }
  }

  createVisitFamily(data: VisitCreationDTO) {
    const token = getMobileToken();
    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      return this.httpMobile.post(`${this.root}/createVisit`, data, options);
    } else {
      return this.http.post(`${this.root}/createVisit`, data);
    }
  }

  startVisit(visitId: number) {
    const token = getMobileToken();
    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      return this.httpMobile.get(`${this.root}/startVisit/${visitId}`, options);
    } else {
      return this.http.get(`${this.root}/startVisit/${visitId}`);
    }
  }

  signVisit(visitId: number) {
    return this.http.get(`${this.root}/signVisit/${visitId}`);
  }

  updateVisit(visit: VisitForUpdateDTO) {
    const token = getMobileToken();
    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      return this.httpMobile.put(`${this.root}/update`, visit, options);
    } else {
      return this.http.put(`${this.root}/update`, visit);
    }
  }

  updateMinimalVisit(visit: VisitCreationDTO, visitId: number) {
    const token = getMobileToken();
    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      return this.httpMobile.put(`${this.root}/updateMinimal/${visitId}`, visit, options);
    } else {
      return this.http.put(`${this.root}/updateMinimal/${visitId}`, visit);
    }
  }

  deleteVisit(visitId: number) {
    const token = getMobileToken();
    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      return this.httpMobile.delete(`${this.root}/${visitId}`, options);
    } else {
      return this.http.delete(`${this.root}/${visitId}`);
    }
  }
}
